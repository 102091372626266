export default [
  {
    path: "/workspace/:code?",
    name: "workspacePage",
    component: () => import("../../../components/dashboard/editors/EditorsPage.vue"),
    meta: {
      requiresAuth: true,
      title: "ADEO | Content Editor | Workspace",
    },
  },
  {
    path: "/users",
    name: "workspaceUsersPage",
    component: () => import("../../../components/dashboard/editors/users/UsersPage.vue"),
    meta: {
      requiresAuth: true,
      title: "ADEO | Content Editor | Users",
      breadcrumb: "Users",
    },
  },
  {
    path: "/bundles",
    name: "workspaceBundlesPage",
    component: () =>
      import("../../../components/dashboard/editors/packages/WorkspacePackagesPage.vue"),
    meta: {
      requiresAuth: true,
      title: "ADEO | Content Editor | Bundles",
      breadcrumb: "Bundles",
    },
  },
  {
    path: "/roles",
    name: "workspaceRolesPage",
    component: () => import("../../../components/dashboard/editors/roles/RolesPage.vue"),
    meta: {
      requiresAuth: true,
      title: "ADEO | Content Editor | Roles",
      breadcrumb: "Roles",
    },
  },
  {
    path: "/invitations",
    name: "workspaceSentInvitationsPage",
    component: () =>
      import("../../../components/dashboard/editors/invitations/SentInvitations.vue"),
    meta: {
      requiresAuth: true,
      title: "ADEO | Content Editor | Invitations",
      breadcrumb: "Invitations",
    },
  },
  {
    path: "/bundles/courses",
    name: "workspaceBundleCoursesPage",
    component: () =>
      import("../../../components/dashboard/editors/packages/courses/BundleCoursePage.vue"),
    meta: {
      requiresAuth: true,
      title: "ADEO | Content Editor | Bundle | Courses ",
      breadcrumb: "Courses",
    },
  },
  {
    path: "/topics",
    name: "workspaceTopicsPage",
    component: () => import("../../../components/dashboard/editors/topics/TopicsPage.vue"),
    meta: {
      requiresAuth: true,
      title: "ADEO | Content Editor | Topics",
      breadcrumb: "Topics",
    },
  },
  {
    path: "/glossaries",
    name: "workspaceGlossariesPage",
    component: () => import("../../../components/dashboard/editors/glossary/GlossaryPage.vue"),
    meta: {
      requiresAuth: true,
      title: "ADEO | Content Editor | Glossaries",
      breadcrumb: "Glossaries",
    },
  },
  {
    path: "/keyconcepts",
    name: "workspaceKeyconceptsPage",
    component: () =>
      import("../../../components/dashboard/editors/keyconcepts/KeyConceptsManagement.vue"),
    meta: {
      requiresAuth: true,
      title: "ADEO | Content Editor | Key Concepts",
      breadcrumb: "Key Concepts",
    },
  },
  {
    path: "/questions",
    name: "workspaceQuestionsPage",
    component: () => import("../../../components/dashboard/editors/questions/QuestionsPage.vue"),
    meta: {
      requiresAuth: true,
      title: "ADEO | Content Editor | Questions",
      breadcrumb: "Questions",
    },
  },
  {
    path: "/quizzes",
    name: "workspaceQuizzesPage",
    component: () =>
      import("../../../components/dashboard/editors/questions/quiz/QuizManagement.vue"),
    meta: {
      requiresAuth: true,
      title: "ADEO | Content Editor | Quizzes",
      breadcrumb: "Quizzes",
    },
  },
];
