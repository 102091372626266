import { createApp } from "vue";
import App from "./App.vue";
import router from "./routes/routes";
import store from "./store/index";
import VueSelect from "vue-select";
import NProgress from "nprogress";
import { Bootstrap5Pagination } from "laravel-vue-pagination";
import { QuillEditor } from "@vueup/vue-quill";

import "@/services/echo";
import "@/services/globals";

// CSS
import "vue-select/dist/vue-select.css";
import "animate.css";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import "@/assets/scss/main.css";
import "./assets/css/nprogressbar.css";

//JS

NProgress.configure({
  showSpinner: true,
});

// Create the app instance
const app = createApp(App);

// Use the plugins
app.use(router);
app.use(store);

//Packages
app.component("v-select", VueSelect);
app.component("Bootstrap5Pagination", Bootstrap5Pagination);
app.component("QuillEditor", QuillEditor);

//Vue global properties
app.config.globalProperties.$nprogress = NProgress;

// Mount the app to the element with id "app"
app.mount("#app");
