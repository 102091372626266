import EditorRoutes from "./EditorRoutes/EditorRoutes";

export default [
  {
    path: "/dashboard",
    name: "authEditorDashboard",
    redirect: "/dashboard/home",
    component: () => import("../../components/Views/DashboardLayouts/EditorDashboardLayout.vue"),
    meta: {
      requiresAuth: true,
      title: "ADEO | Content Editor | Dashboard",
      breadcrumb: "Dashboard",
    },
    children: [
      {
        path: "home",
        name: "authEditorDashboardIndex",
        component: () => import("../../components/dashboard/Index.vue"),
        meta: {
          requiresAuth: true,
          title: "ADEO | Content Editor | Dashboard",
        },
      },
      {
        path: "profile",
        name: "authProfile",
        component: () => import("../../components/dashboard/user/Profile.vue"),
        meta: {
          requiresAuth: true,
          title: "ADEO | Content Editor | Profile",
        },
      },
      ...EditorRoutes,
    ],
  },
];
