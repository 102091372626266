import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  forceTLS: false,
  wsPort: process.env.VUE_APP_PUSHER_WS_PORT || 6001,
  disableStats: true,
  enabledTransports: ["ws", "wss"],
});
