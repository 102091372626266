import helpers from "@/services/helpers";
import NProgress from "nprogress";
import router from "@/routes/routes";

const state = {
  currentWorkSpace: {},
  myWorkSpaces: [],
  otherWorkSpaces: [],
  workspaceInvitations: [],
  userWorkspaceRoles: [],
  workspacePermissions: [],
  workspacePackages: [],
  workspaceLevels: [],
  workspaceCourses: [],
  workspaceUsers: [],
  workspaceTopics: [],
  workspaceTopicNotes: [],
  workspaceQuizzes: [],
  workspaceQuestions: [],
  allWorkSpaceRoles: [],
};

const mutations = {
  SET_CURRENT_EDITOR: (state, data) => (state.currentWorkSpace = data),
  SET_MY_WORKSPACES: (state, data) => (state.myWorkSpaces = data),
  SET_OTHER_WORKSPACES: (state, data) => (state.otherWorkSpaces = data),
  UPDATE_MY_WORKSPACES: (state, data) => state.myWorkSpaces.unshift(data),
  SET_WORKSPACE_INVITATIONS: (state, data) => (state.workspaceInvitations = data),
  SET_USER_WORKSPACE_ROLES: (state, data) => (state.userWorkspaceRoles = data),
  SET_WORKSPACE_PERMISSIONS: (state, data) => (state.workspacePermissions = data),
  SET_WORKSPACE_PACKAGES: (state, data) => (state.workspacePackages = data),
  SET_WORSPACE_COURSES: (state, data) => (state.workspaceCourses = data),
  SET_WORSPACE_USERS: (state, data) => (state.workspaceUsers = data),
  SET_WORKSPACE_TOPICS: (state, data) => (state.workspaceTopics = data),
  SET_WORKSPACE_TOPIC_NOTES: (state, data) => (state.workspaceTopicNotes = data),
  SET_WORKSPACE_QUIZZES: (state, data) => (state.workspaceQuizzes = data),
  SET_WORKSPACE_QUESTIONS: (state, data) => (state.workspaceQuestions = data),
  SET_ALL_WORKSPACE_ROLES: (state, data) => (state.allWorkSpaceRoles = data),
  REMOVE_USER_ROLE: (state, id) => {
    helpers.removeItemByID(state.userWorkspaceRoles, id);
  },
  UPDATE_USER_ROLES: (state, roleIds) => {
    const roles = state.allWorkSpaceRoles.filter(role => roleIds.includes(role.id));
    state.userWorkspaceRoles = [...state.userWorkspaceRoles, ...roles];
  },
  RESET_WORKSPACE_DATA: state => {
    state.userWorkspaceRoles = [];
    state.workspacePermissions = [];
    state.workspacePackages = [];
    state.workspaceCourses = [];
    state.workspaceUsers = [];
    state.workspaceTopics = [];
    state.workspaceTopicNotes = [];
    state.workspaceQuizzes = [];
    state.workspaceQuestions = [];
    state.allWorkSpaceRoles = [];
    state.workspaceLevels = [];
  },
  REMOVE_WORKSPACE_INVITATION: (state, invitationId) => {
    helpers.removeItemByID(state.workspaceInvitations, invitationId);
  },
  SET_WORKSPACE_LEVELS: (state, data) => (state.workspaceLevels = data),
};

const actions = {
  async getWorkSpacesAction({ commit }) {
    try {
      NProgress.start();
      const res = await api.get("/list");
      commit("SET_MY_WORKSPACES", res.data.data?.my_editors);
      commit("SET_OTHER_WORKSPACES", res.data.data?.other_editors);
    } catch (err) {
      helpers.errorResponseHandler(err);
    } finally {
      NProgress.done();
    }
  },

  setWorkSpacesAction({ commit }, data) {
    commit("SET_MY_WORKSPACES", data?.my_editors || []);
    commit("SET_OTHER_WORKSPACES", data?.other_editors || []);
  },

  async lauchWorkspaceAction({ commit, dispatch }, workspace) {
    try {
      NProgress.start();

      dispatch("resetCurrentWorkspaceData");
      commit("SET_CURRENT_EDITOR", workspace);
      dispatch("setUserWorkspacePermissions", workspace);

      const res = await api.get(`${workspace.id}/view`);
      dispatch("handleSetCurrentWorkspaceAction", res.data.data);
    } catch (err) {
      helpers.errorResponseHandler(err);
    } finally {
      NProgress.done();
    }
  },

  async setUserWorkspaceRoles({ commit, rootState, dispatch }, workspace) {
    const { authUser } = rootState.authentication;
    if (helpers.isEmptyObject(authUser) || !authUser?.id) {
      dispatch("authentication/forceUserToLoginPageAction", null, { root: true });
      return false;
    }

    const workspaceUser = helpers.findItemByID(workspace.users, authUser.id);
    if (!workspaceUser) {
      helpers.errorAlert("Account Not Found", "Your account for the workspace was not found");
      return false;
    }

    const roles = workspaceUser?.roles?.roles || [];
    commit("SET_USER_WORKSPACE_ROLES", roles);
    return true;
  },

  setUserWorkspacePermissions({ commit }, workspace) {
    const permissions = workspace?.permissions || [];
    commit("SET_WORKSPACE_PERMISSIONS", permissions);
  },

  setWorkspacePackagesAction({ commit }, workspace = {}) {
    const packages = workspace?.bundles || [];
    commit("SET_WORKSPACE_PACKAGES", packages);
  },

  setWorkspaceCoursesAction({ commit }, workspace = {}) {
    const packages = workspace?.bundles?.length ? workspace.bundles : [];
    const courses = packages.flatMap(x => x.courses || []);
    commit("SET_WORSPACE_COURSES", courses);
  },

  setWorkspaceUsersAction({ commit }, workspace = {}) {
    const users = workspace?.users || [];
    commit("SET_WORSPACE_USERS", users);
  },

  setAllWorkspaceRoles({ commit }, workspace = {}) {
    const roles = workspace?.roles || [];
    commit("SET_ALL_WORKSPACE_ROLES", roles);
  },

  userCan({ state }, permissions) {
    return permissions.some(x => state.workspacePermissions.includes(x));
  },

  hasRole({ state }, roles) {
    return roles.some(x => state.userWorkspaceRoles.includes(x));
  },

  resetCurrentWorkspaceData({ commit, dispatch }) {
    commit("RESET_WORKSPACE_DATA");
    dispatch("workspaceModule/resetCurrentWorkspaceData", null, { root: true });
  },

  async getUserWorkspaceInvitationsAction({ commit }) {
    try {
      NProgress.start();
      const res = await api.get("/user/workspace-invitations");
      commit("SET_WORKSPACE_INVITATIONS", res.data.data);
    } catch (e) {
      console.error("Error getting user workspace invitations");
    } finally {
      NProgress.done();
    }
  },

  async acceptWorkspaceInvitationAction({ commit, dispatch }, invitation) {
    try {
      NProgress.start();
      const res = await api.get(`/accept/invitation/${invitation.id}`);
      helpers.successAlert(res.data.message);
      dispatch("resetCurrentWorkspaceData");
      dispatch("handleSetCurrentWorkspaceAction", res.data.data);
      commit();
    } catch (error) {
      helpers.errorResponseHandler(error);
    } finally {
      NProgress.done();
    }
  },

  async handleSetCurrentWorkspaceAction({ commit, dispatch }, workspace) {
    const status = await dispatch("setUserWorkspaceRoles", workspace);
    if (!status) return;

    commit("SET_CURRENT_EDITOR", workspace);

    dispatch("setUserWorkspacePermissions", workspace);
    dispatch("setWorkspacePackagesAction", workspace);
    dispatch("setWorkspaceCoursesAction", workspace);
    dispatch("setWorkspaceUsersAction", workspace);
    dispatch("setAllWorkspaceRoles", workspace);

    router.push({
      name: "workspacePage",
      params: { code: workspace?.workspace_code_name || workspace?.code || "" },
    });
  },

  async declineWorkspaceInvitationAction({ commit }, invitation) {
    try {
      NProgress.start();
      const res = await api.get(`/decline/invitation/${invitation.id}`);
      helpers.successAlert(res.data.message);
      commit("REMOVE_WORKSPACE_INVITATION", invitation.id);
    } catch (error) {
      helpers.errorResponseHandler(error);
    } finally {
      NProgress.done();
    }
  },

  async getAllWorkspaceLevelsCoursesAndTopicsAction({ dispatch, state }) {
    try {
      NProgress.start();
      const res = await api.get(`/workspace/levels-courses/${state.currentWorkSpace.id}`);
      dispatch("setLevelsCoursesTopics", res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      NProgress.done();
    }
  },

  setLevelsCoursesTopics({ commit }, data) {
    commit("SET_WORKSPACE_LEVELS", data.levels);
    commit("SET_WORSPACE_COURSES", data.courses);

    let topics = data.levels.flatMap(c => c?.courses)?.flatMap(t => t?.topics);
    commit("SET_WORKSPACE_TOPICS", topics);

    let notes = topics?.flatMap(n => n.notes);
    commit("SET_WORKSPACE_TOPIC_NOTES", notes);
  },

  async getWorkspacePackages({ commit, state }) {
    try {
      const params = {
        editor_id: state.currentWorkSpace?.id,
      };

      NProgress.start();
      const res = await api.get("/workspace/packages", { params });
      commit("SET_WORKSPACE_PACKAGES", res.data.data);
    } catch (error) {
      console.error("Error getting workspace packages");
    } finally {
      NProgress.done();
    }
  },
};

const getters = {
  currentWorkSpace: state => state.currentWorkSpace,
  myWorkSpaces: state => state.myWorkSpaces,
  otherWorkSpaces: state => state.otherWorkSpaces,
  allWorkSpaces: state => [...state.myWorkSpaces, ...state.otherWorkSpaces],
  workspaceInvitations: state => state.workspaceInvitations,
  noWorkSpaceInvitation: state => state.workspaceInvitations.length === 0,
  can: state => permissions => {
    return permissions.some(x => state.workspacePermissions.includes(x));
  },
  hasRole: state => roles => {
    return roles.some(x => state.userWorkspaceRoles.includes(x));
  },
  workspacePackages: state => state.workspacePackages,
  workspaceCourses: state => state.workspaceCourses,
  workspaceUsers: state => state.workspaceUsers,
  workspaceTopics: state => state.workspaceTopics,
  workspaceTopicNotes: state => state.workspaceTopicNotes,
  workspaceQuizzes: state => state.workspaceQuizzes,
  workspaceQuestions: state => state.workspaceQuestions,
  allWorkSpaceRoles: state => state.allWorkSpaceRoles,
  userWorkspaceRoles: state => state.userWorkspaceRoles,
  workspaceLevels: state => state.workspaceLevels,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
