import NProgress from "nprogress";

const state = {
  countries: [],
  isSidebarOpen: true,
  sidebarItemsTypes: {
    packages: "packages",
    bundles: "bundles",
    courses: "courses",
    users: "users",
  },
  contentEditorPermissions: [],
  activePage: "",
  currencies: [],
  subscriptionType: {
    plan: "PLAN",
    combo: "COMBO BUNDLE",
    courses: "PLAN FEATURE",
  },
  subscriptionIntervals: [],
  academicLevels: [],
  ownerAdminEditorRoles: ["Owner", "Admin", "Editor"],
  ownerAdminRoles: ["Owner", "Admin"],
};

const mutations = {
  SET_COUNTRIES: (state, data) => (state.countries = data),
  SET_SIDEBAR_OPEN: (state, data) => (state.isSidebarOpen = data),
  SET_CONTENT_EDITOR_PERMISSIONS: (state, data) => (state.contentEditorPermissions = data),
  SET_ACTIVE_PAGE: (state, data) => (state.activePage = data),
  SET_CURRENCIES: (state, data) => (state.currencies = data),
  SET_SUBSCRIPTION_INTERVALS: (state, data) => (state.subscriptionIntervals = data),
  SET_ACADEMIC_LEVELS: (state, data) => (state.academicLevels = data),
};

const actions = {
  async getListOfCountries({ commit }) {
    try {
      NProgress.start();
      const res = await api.get("/countries");
      commit("SET_COUNTRIES", res.data);
    } catch (error) {
      console.error("Error getting list of countries");
    } finally {
      NProgress.done(true);
    }
  },

  sideBarStatusAction({ commit }, value) {
    commit("SET_SIDEBAR_OPEN", value);
  },

  async getContentEditionPermissionsAction({ commit }) {
    try {
      NProgress.start();
      const res = await api.get("/permissions");
      commit("SET_CONTENT_EDITOR_PERMISSIONS", res.data.data);
    } catch (e) {
      console.error("Error getting list of content editors permissions");
    } finally {
      NProgress.done(true);
    }
  },

  getActivePageAction({ commit }, pageName) {
    commit("SET_ACTIVE_PAGE", pageName);
  },

  async getCurrenciesAction({ commit }) {
    try {
      NProgress.start();
      const res = await api.get("/currencies");
      commit("SET_CURRENCIES", res.data);
    } catch (error) {
      console.error("Error getting list of currencies");
    } finally {
      NProgress.done(true);
    }
  },

  async getActiveSubscriptionIntervalsAction({ commit }) {
    try {
      NProgress.start();
      const res = await api.get("/subscription/active/intervals");
      commit("SET_SUBSCRIPTION_INTERVALS", res.data);
    } catch (error) {
      console.error("Error getting active subscription intervals");
    } finally {
      NProgress.done(true);
    }
  },

  async getAcademicLevelsAction({ commit }) {
    try {
      NProgress.start();
      const res = await api.get("/academic-levels");
      commit("SET_ACADEMIC_LEVELS", res.data);
    } catch (error) {
      console.error("Error getting academic levels");
    } finally {
      NProgress.done(true);
    }
  },
};

const getters = {
  countries: state => state.countries,
  isSidebarOpen: state => state.isSidebarOpen,
  sidebarItemsTypes: state => state.sidebarItemsTypes,
  contentEditorPermissions: state => state.contentEditorPermissions,
  activePage: state => state.activePage,
  currencies: state => state.currencies,
  subscriptionType: state => state.subscriptionType,
  subscriptionIntervals: state => state.subscriptionIntervals,
  academicLevels: state => state.academicLevels,
  ownerAdminEditorRoles: state => state.ownerAdminEditorRoles,
  ownerAdminRoles: state => state.ownerAdminRoles,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
