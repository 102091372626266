import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";
import store from "@/store/index";

import GuestRoutes from "./GuestRoutes/GuestRoutes";
import AuthRoutes from "./AuthRoutes/AuthRoutes";

const routes = [
  ...GuestRoutes,
  ...AuthRoutes,
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  window.document.title = to.meta && to.meta?.title ? to.meta.title : "ADEO | Content Editor";

  // Redirect authenticated user to signin if page requires authentication.
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters["authentication/isLoggedIn"]) {
      return next({ path: "/signin" });
    }
  }

  // Redirect authenticated user to dashboard if page requireds unauthentication.
  if (to.matched.some(record => record.meta.requiresGuest)) {
    if (store.getters["authentication/isLoggedIn"]) {
      return next({ path: "/dashboard" });
    }
  }

  next();
});

router.afterEach(() => {
  NProgress.done(true);
});

export default router;
