import axios from "axios";
import router from "@/routes/routes";
import store from "@/store/index";
import constants from "@/services/constants";

let baseURL = process.env.VUE_APP_API_SERVER_URL;

const instance = axios.create({
  baseURL,
  headers: {
    Accepted: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

instance.interceptors.request.use(
  config => {
    const token = store.getters["authentication/token"];
    config.headers["api-token"] = token;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response?.status === 401) {
      localStorage.removeItem(constants.APP_STORE);
      return router.push("/signin");
    }
    return Promise.reject(error);
  }
);

export default instance;
