import moment from "moment";
import Swal from "sweetalert2";

export default {
  // open modal
  openModal(modalID) {
    new bootstrap.Modal(modalID)?.show();
  },

  // hide modal
  hideModal(modalID) {
    new bootstrap.Modal(modalID)?.hide();
  },

  // success alert
  successAlert(message) {
    window.Toast.fire("", message, "success");
  },

  // error alert toast
  errorToastAlert(message, title = "Oops") {
    window.Toast.fire(title, message, "error");
  },

  // general alert toast
  infoToastAlert(message, title = "") {
    window.Toast.fire(title, message, "info");
  },

  infoAlert(title, message) {
    Swal.fire(title, message, "info");
  },

  successAlertBox(title = "Successful", message) {
    Swal.fire(title, message, "success");
  },

  // error alert
  errorAlert(title = "Failed", error) {
    Swal.fire(title, error, "error");
  },

  resetAnItem(item) {
    if (!item) return;
    if (typeof item == "object") {
      Object.keys(item).forEach(k => {
        if (item[k]) item[k] = "";
      });
    }
  },

  resetAnObject(item) {
    if (!item) return;
    if (typeof item == "object") {
      Object.keys(item).forEach(k => {
        if (typeof item[k] == "undefined") {
          item[k] = "";
        } else if (typeof item[k] == "string") {
          item[k] = "";
        } else if (typeof item[k] == "number") {
          item[k] = "";
        } else if (Array.isArray(item[k])) {
          this.resetAnObject(item[k]);
        }
      });
    }

    if (Array.isArray(item)) {
      if (item.length == 0) {
        item = [];
      } else {
        item.length = 1;
        item.forEach((x, k, item) => {
          if (typeof item[k] == "object" || Array.isArray(item[k])) {
            this.resetAnObject(item[k]);
          }
        });
      }
    }
  },

  resetFileInput(id) {
    const fields = document.querySelectorAll(id);
    [...fields].forEach(x => (x.value = ""));
  },

  selectAllItems(ARRAY, itemsKey, checked, checkedKey) {
    let items = [];
    ARRAY.forEach(y => (y[checkedKey] = false));
    if (checked) {
      items = ARRAY.map(x => x[itemsKey]);
      ARRAY.forEach(y => (y[checkedKey] = true));
    }
    return items;
  },

  selectAnItem(ARRAY, item, itemsKey, checked) {
    if (checked) {
      ARRAY.push(item[itemsKey]);
    } else {
      ARRAY = ARRAY.filter(x => x !== item[itemsKey]);
    }
    return ARRAY;
  },

  validationError(error) {
    const firstError = Object.entries(error)[0][1][0];
    this.errorAlert("Validation Error", firstError);
  },

  generalError(message) {
    this.errorAlert("General Error!", message);
  },

  notFoundError(message) {
    this.errorAlert("Not Found", message);
  },

  forbiddenError(message) {
    this.errorAlert("Forbidden Request Error", message);
  },

  serverError(error) {
    this.errorAlert("Server Error", error);
  },

  conflictError(error) {
    this.errorAlert("Data Exists Error", error);
  },

  largeRequestError(error) {
    this.errorAlert("Request Entity Large", error);
  },

  errorResponseHandler(ERRORS) {
    let status = ERRORS?.response?.status || ERRORS.data?.errors;

    if (status === 422) {
      this.validationError(ERRORS.response.data?.errors);
    }

    if (status === 400) {
      this.generalError(
        ERRORS.response.data?.message ||
          ERRORS.response.data?.error ||
          "An error occured on the server"
      );
    }

    if (status === 403) {
      this.forbiddenError(
        ERRORS.response.data?.message ||
          ERRORS.response.data?.error ||
          "A forbidden error occured. 403"
      );
    }

    if (status === 404) {
      this.notFoundError(
        ERRORS.response.data?.message ||
          ERRORS.response.data?.error ||
          "A not found error occured. 404"
      );
    }

    if (status === 409) {
      this.conflictError(
        ERRORS.response.data?.error ||
          ERRORS.response.data?.message ||
          "A data conflict error occured. 409"
      );
    }

    if (status === 413) {
      this.largeRequestError(
        ERRORS.response.data?.error ||
          ERRORS.response.data?.message ||
          "Request entity too large. 413"
      );
    }

    if (status === 505 || status === 500) {
      this.serverError(
        ERRORS.response.data?.error ||
          ERRORS.response.data?.message ||
          "A server error occurred. 500"
      );
    }
  },

  confirmDialog(title, text, confirmText, type) {
    return Swal.fire({
      title: title || "Are you sure ?",
      text: text,
      icon: type || "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirmText || "Yes",
    });
  },

  findItemByID(ARRAY, ID) {
    return ARRAY.find(element => element.id == ID);
  },

  findItemByKey(ARRAY, key, value) {
    return ARRAY.find(element => element[key] == value);
  },

  findItemByIndex(ARRAY, index) {
    return ARRAY.find((element, i) => i === index);
  },

  removeItemByID(ARRAY, ID) {
    const indexToRemove = ARRAY.findIndex(item => item.id === ID);
    if (indexToRemove !== -1) {
      ARRAY.splice(indexToRemove, 1);
    }
    return ARRAY;
  },

  removeItemByKey(ARRAY, key, ID) {
    return ARRAY.filter(element => element[key] !== ID);
  },

  removeItemByIndex(ARRAY, index) {
    return ARRAY.filter((item, i) => i !== index);
  },

  replaceItemById(ARRAY, newItem, ID, key = "id") {
    const itemIndex = ARRAY.findIndex(item => item[key] === ID);
    if (itemIndex !== -1) {
      ARRAY.splice(itemIndex, 1, newItem);
    }
  },

  stripHTML(html) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc?.body?.textContent || "";
  },

  // image upload
  uploadFile(e) {
    let poster = "";
    let file = e.target.files[0];
    const types = ["image/jpeg", "image/png", "image/jpg"];
    if (types.includes(file.type)) {
      if (file.size < 10097152) {
        poster = file;
      } else {
        Swal.fire("Image Size Too Large", "Image size cannot be more than 10MB", "warning");
      }
    } else {
      Swal.fire(
        "File Format not Supported",
        "Supported file types are jpg | jpeg | png",
        "warning"
      );
    }

    return poster;
  },

  // preview uploaded image
  deleteInputRow(ARRAY, item) {
    let idx = ARRAY.indexOf(item);
    if (idx > -1) {
      ARRAY.splice(idx, 1);
    }
    return ARRAY;
  },

  isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  },

  isNotEmptyObject(obj) {
    return this.isEmptyObject(obj) === false;
  },

  reduceText(text, length = 10, suffix = "...") {
    if (!text) return;

    if (text?.length > length) {
      return text?.substring(0, length) + suffix;
    } else {
      return text;
    }
  },

  dateToFullDate(date) {
    return date && moment(date).format("Do MMMM, YYYY");
  },

  dateToShortDate(date) {
    return date && moment(date).format("Do MMM, YYYY");
  },

  dateToSlashed(date) {
    return date && moment(date).format("DD/MM/YYYY");
  },

  timeToAMPM(time) {
    return time && moment(time, "hh:mm").format("hh:mm A");
  },

  shortDateAndTime(date) {
    return date && moment(date).format("Do MMM, YYYY hh:mm A");
  },

  dateDiffInDays(endDate, startDate) {
    return endDate && startDate ? moment(endDate).diff(moment(startDate), "days") : "";
  },

  downloadFile(bufferData, mimeType, fileName) {
    let blob = new Blob([bufferData], { type: `${mimeType}` });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}`;
    link.click();
  },

  buildFormData(formData, data, parentKey) {
    if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey, value);
    }
  },

  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
  },

  visiblePassword(self, main, confirm, current = null) {
    let x = self.$refs[main];
    let y = self.$refs[confirm];
    if (x.type === "password" || y.type === "password") {
      x.type = "text";
      y.type = "text";
    } else {
      x.type = "password";
      y.type = "password";
    }

    if (current) {
      let z = self.$refs[current];
      z.type = z.type === "password" ? "text" : "password";
    }
  },

  pushAllItemsIntoArray(ARRAY_CONTAINER, ARRAY_DATA, KEY_TO_PUSH, isChecked) {
    ARRAY_CONTAINER = [];
    if (isChecked) {
      ARRAY_DATA.forEach(x => ARRAY_CONTAINER.push(x[KEY_TO_PUSH]));
    }
    return ARRAY_CONTAINER;
  },

  checkAllItemsSelected(MAIN_ARRAY, SUB_ARRAY) {
    if (SUB_ARRAY.length == MAIN_ARRAY.length) {
      return true;
    } else {
      return false;
    }
  },

  joinPhraseAsWord(phrase) {
    if (!phrase) return;
    return phrase.split(" ").join("");
  },

  arrayBufferErrorResponse(err) {
    let blob = new Blob([err.response.data], {
      type: "text/plain; charset=utf-8",
    });
    if (err.response.status === 422) {
      blob.text().then(text => {
        Swal.fire("Failed!", JSON.parse(text).error, "warning");
      });
    }
    if (err.response.status === 404) {
      blob.text().then(text => {
        const message = JSON.parse(text)?.error || JSON.parse(text)?.message;
        Swal.fire("Not Available!", message, "warning");
      });
    }
  },

  upperCase(text) {
    return text && text.toUpperCase();
  },

  alphabeticalOrder(index) {
    if (index === "") return;
    const alphabets = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    return alphabets[index];
  },

  switchModalContent(modalContent, switchType = false) {
    Object.keys(modalContent).forEach(key => (modalContent[key] = switchType));
  },

  sumOfArrayColumn(ARRAYDATA, column) {
    return ARRAYDATA.map(item => parseFloat(item[column]) || 0.0).reduce((a, b) => a + b, 0);
  },

  getValueOfSearchParam(param) {
    const currentURL = window.location.href;
    const urlParams = new URLSearchParams(currentURL);
    return urlParams.get(param);
  },

  handleFileValidation(event, extentions, ref, extentionText = "", maxSize = 2) {
    const file = event.target.files[0];
    if (!file) return;
    const fileInput = ref;
    const filePath = fileInput.value;

    // Allowing file type
    const allowedExtensions = extentions;
    try {
      if (!allowedExtensions.exec(filePath)) {
        throw {
          title: "Validation Error",
          text: `File type is not supported. Supported file types are ${extentionText}.`,
        };
      } else if (file.size > maxSize * 1024 * 1024) {
        throw {
          title: "Validation Error",
          text: `File size cannot be larger than ${maxSize}MB.`,
        };
      } else {
        return {
          status: true,
          file,
        };
      }
    } catch (err) {
      fileInput.value = "";
      return {
        status: false,
        err,
      };
    }
  },

  getLettersAbbreviation(str, numChars = 1) {
    if (!str) return str;
    if (numChars <= 0) numChars = 1;

    const words = str.split(/\s+/);

    const result = words.map(word => word.substring(0, numChars)).join("");

    return result.toUpperCase();
  },

  generateUniqueId(length = 9) {
    return Math.random().toString(36).substr(2, length);
  },

  validateImageFile(event, imageInput, maxSize = 2) {
    const extsArr = ["jpg", "jpeg", "png"].join(", ");
    const extentions = /(\.jpeg|\.jpg|\.png)$/i;

    return this.handleFileValidation(event, extentions, imageInput, extsArr, maxSize);
  },

  fileUploadConfig() {
    let config, uploadProgress;

    config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: progressEvent => {
        let load = (progressEvent.loaded / progressEvent.total) * 100;
        uploadProgress = Math.floor(load) + "%";
      },
    };

    return { config, uploadProgress };
  },

  searchTableForItems(ARRAYDATA, searchText) {
    if (searchText) {
      ARRAYDATA = ARRAYDATA.filter(row => {
        return Object.keys(row).some(key => {
          return String(row[key]).toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        });
      });
    }
    return ARRAYDATA;
  },

  getGreeting() {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (currentHour >= 0 && currentHour < 12) {
      return "Good morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  },

  previewImage(file, imagePreviews = []) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        imagePreviews.push(e.target.result);
        resolve();
      };

      reader.onerror = error => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  },

  validateAndProcessImages(files, onSuccess, onError) {
    const supportedTypes = ["image/jpeg", "image/png", "image/jpg"];
    const processedFiles = [];

    try {
      files.forEach((file, i) => {
        if (!supportedTypes.includes(file.type)) {
          throw new Error(
            `IMAGE ${i + 1}: File Format Not Supported: Supported file types are jpg, jpeg, png`
          );
        }
        if (file.size > 2.05 * 1024 * 1024) {
          throw new Error("Image Size Too Large: Image size cannot be more than 2MB");
        }
        processedFiles.push(file);
      });
      onSuccess(processedFiles);
    } catch (error) {
      onError(error);
    }
  },
};
