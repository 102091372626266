import NProgress from "nprogress";

const state = {
  currentWorkspaceUser: {},
  paginatedWorkspaceRoles: [],
  paginatedWorkspaceUsers: [],
  paginatedWorkspaceInvites: [],
  paginatedWorkspacePackages: [],
  paginatedWorkspaceLevels: [],
  paginatedWorkspaceCourses: [],
};

const mutations = {
  SET_CURRENT_WORKSPACE_USER: (state, data) => (state.currentWorkspaceUser = data),
  SET_PAGINATED_WORKSPACE_ROLES: (state, data) => (state.paginatedWorkspaceRoles = data),
  UPDATE_WOKSPACE_ROLES: (state, data) => state.paginatedWorkspaceRoles?.data.unshift(data),
  SET_PAGINATED_WORKSPACE_USERS: (state, data) => (state.paginatedWorkspaceUsers = data),
  RESET_WORKSPACE_DATA: state => {
    state.paginatedWorkspaceRoles = [];
    state.paginatedWorkspaceUsers = [];
    state.paginatedWorkspaceInvites = [];
    state.paginatedWorkspacePackages = [];
    state.paginatedWorkspaceLevels = [];
    state.paginatedWorkspaceCourses = [];
    state.currentWorkspaceUser = {};
  },
  SET_PAGINATED_WORKSPACE_INVITES: (state, data) => (state.paginatedWorkspaceInvites = data),
  SET_PAGINATED_WORKSPACE_PACKAGES: (state, data) => (state.paginatedWorkspacePackages = data),
  SET_PAGINATED_WORKSPACE_LEVELS: (state, data) => (state.paginatedWorkspaceLevels = data),
  SET_PAGINATED_WORKSPACE_COURSES: (state, data) => (state.paginatedWorkspaceCourses = data),
};

const actions = {
  setCurrentWorkspaceUserAction({ commit }, user) {
    commit("SET_CURRENT_WORKSPACE_USER", user);
  },

  async getAllRolesAction({ commit, rootState }, filters = {}) {
    try {
      const { currentWorkSpace } = rootState.editorModule;

      const params = {
        ...filters,
        page: filters?.page || 1,
        editor_id: currentWorkSpace?.id,
      };

      NProgress.start();
      const res = await api.get("/roles", { params });
      commit("SET_PAGINATED_WORKSPACE_ROLES", res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      NProgress.done();
    }
  },

  async getAllUsersActions({ commit, rootState }, filters = {}) {
    try {
      const { currentWorkSpace } = rootState.editorModule;
      const params = {
        ...filters,
        page: filters?.page || 1,
        editor_id: currentWorkSpace?.id,
      };

      NProgress.start();
      const res = await api.get("/workspace-users", { params });
      commit("SET_PAGINATED_WORKSPACE_USERS", res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      NProgress.done();
    }
  },

  resetCurrentWorkspaceData({ commit }) {
    commit("RESET_WORKSPACE_DATA");
  },

  async getPaginatedWorkspaceInvitesAction({ commit, rootState }, filters = {}) {
    try {
      const { currentWorkSpace } = rootState.editorModule;
      const params = {
        ...filters,
        page: filters?.page || 1,
        editor_id: currentWorkSpace?.id,
      };

      NProgress.start();
      const res = await api.get("/workspace-invitations", { params });
      commit("SET_PAGINATED_WORKSPACE_INVITES", res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      NProgress.done();
    }
  },

  async getPaginatedWorkspacePackagesAction({ commit, rootState }, filters = {}) {
    try {
      const { currentWorkSpace } = rootState.editorModule;
      const params = {
        ...filters,
        page: filters?.page || 1,
        editor_id: currentWorkSpace?.id,
      };

      NProgress.start();
      const res = await api.get("/subscription/plans", { params });
      commit("SET_PAGINATED_WORKSPACE_PACKAGES", res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      NProgress.done();
    }
  },

  async getPaginatedWorkspaceLevelsAction({ commit, rootState }, filters = {}) {
    try {
      const { currentWorkSpace } = rootState.editorModule;
      const params = {
        ...filters,
        page: filters?.page || 1,
        editor_id: currentWorkSpace?.id,
      };

      NProgress.start();
      const res = await api.get("/levels", { params });
      commit("SET_PAGINATED_WORKSPACE_LEVELS", res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      NProgress.done();
    }
  },

  async getPaginatedWorkspaceCoursesAction({ commit, rootState }, filters = {}) {
    try {
      const { currentWorkSpace } = rootState.editorModule;
      const params = {
        ...filters,
        // page: filters?.page || 1,
        editor_id: currentWorkSpace?.id,
      };

      NProgress.start();
      const res = await api.get("/courses", { params });
      commit("SET_PAGINATED_WORKSPACE_COURSES", res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      NProgress.done();
    }
  },
};

const getters = {
  currentWorkspaceUser: state => state.currentWorkspaceUser,
  paginatedWorkspaceRoles: state => state.paginatedWorkspaceRoles,
  paginatedWorkspaceUsers: state => state.paginatedWorkspaceUsers,
  paginatedWorkspaceInvites: state => state.paginatedWorkspaceInvites,
  paginatedWorkspacePackages: state => state.paginatedWorkspacePackages,
  paginatedWorkspaceLevels: state => state.paginatedWorkspaceLevels,
  paginatedWorkspaceCourses: state => state.paginatedWorkspaceCourses,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
