import { createStore } from "vuex";
import createPersist from "vuex-persist";
import constants from "@/services/constants";

import authentication from "./modules/authentication";
import generalModule from "./modules/generalModule";
import editorModule from "./modules/editorModule";
import workspaceModule from "./modules/workspaceModule";

const vuexPersist = new createPersist({
  key: constants.APP_STORE,
  storage: window.localStorage,
});

const store = createStore({
  modules: {
    authentication,
    generalModule,
    editorModule,
    workspaceModule,
  },
  plugins: [vuexPersist.plugin],
});

export default store;
