export default [
  {
    path: "/",
    name: "landingPage",
    component: () => import("../../components/Views/LandingPageLayouts/HomeLayout.vue"),
    children: [
      {
        path: "",
        name: "contentEditorIndex",
        component: () => import("../../components/Index.vue"),
        meta: {
          requiresGuest: true,
          title: "ADEO | Content Editor",
        },
      },
      {
        path: "signin",
        name: "editorSignIn",
        component: () => import("../../components/guestpages/SignIn.vue"),
        meta: {
          requiresGuest: true,
          title: "ADEO | Content Editor | Signin",
        },
      },
      {
        path: "signup",
        name: "editorSignUp",
        component: () => import("../../components/guestpages/SignUp.vue"),
        meta: {
          requiresGuest: true,
          title: "ADEO | Content Editor | Register",
        },
      },
      {
        path: "forgot-password",
        name: "editorForgotPassword",
        component: () => import("../../components/guestpages/ForgotPasswordPage.vue"),
        meta: {
          requiresGuest: true,
          title: "ADEO | Content Editor | Forgot Password",
        },
      },
      {
        path: "reset-password",
        name: "editorResetPassword",
        component: () => import("../../components/guestpages/ResetPassword.vue"),
        meta: {
          requiresGuest: true,
          title: "ADEO | Content Editor | Reset Password",
        },
      },
      {
        path: "verify-email",
        name: "verifyEmailPage",
        component: () => import("../../components/guestpages/VerificationPage.vue"),
        meta: {
          requiresGuest: true,
          title: "ADEO | Content Editor | Verify Email",
        },
      },
      {
        path: "/join-workspace",
        name: "workspaceInvitationGuestPage",
        component: () => import("../../components/guestpages/WorkspaceInvitationPage.vue"),
        meta: {
          requiresGuest: true,
          title: "ADEO | Content Editor | Workspace Invitation",
        },
      },
    ],
  },
];
