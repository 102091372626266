import router from "../../routes/routes";
import helpers from "@/services/helpers";
import constants from "@/services/constants";
import NProgress from "nprogress";

const state = {
  authUser: {},
  newlyRegistered: {},
  showEmailVerification: false,
  showLoader: {
    buttonLoader: false,
    signInLoader: false,
    signUpLoader: false,
    googleLoginLoader: false,
    verifyEmailLoader: false,
    resendPinLoader: false,
    signOutLoader: false,
  },
  workspaceInvitation: {},
  invitationToken: null,
};

const mutations = {
  setAuthUser: (state, data) => (state.authUser = data),
  setNewlyResgistered: (state, data) => (state.newlyRegistered = data),
  setLoader: (state, { loader = "buttonLoader", value }) => {
    state.showLoader[loader] = value;
  },
  setShowEmailVerification: (state, value) => (state.showEmailVerification = value),
  SET_WORKSPACE_INVITATION: (state, data) => (state.workspaceInvitation = data),
  RESET_WORKSPACE_INVITATION: state => (state.workspaceInvitation = {}),
  SET_INVITATION_TOKEN: (state, data) => (state.invitationToken = data),
};

const actions = {
  async loginWithGoogleAction({ commit, dispatch }, payload) {
    try {
      commit("setLoader", { loader: "googleLoginLoader", value: true });
      const response = await api.post("/google/signin", payload);
      helpers.successAlert("Welcome: Login Successfull");
      commit("setAuthUser", response.data?.data?.user);
      await dispatch("editorModule/setWorkSpacesAction", response.data?.data?.workspaces, {
        root: true,
      });
      router.push({ name: "authEditorDashboard" });
    } catch (error) {
      helpers.errorResponseHandler(error);
    } finally {
      commit("setLoader", { loader: "googleLoginLoader", value: false });
    }
  },

  async loginWithEmailAndPasswordAction({ commit, dispatch }, payload) {
    try {
      commit("setLoader", { loader: "signInLoader", value: true });
      const response = await api.post("/signin", payload);

      const user = response.data?.data?.user;
      const workspaces = response.data?.data?.workspaces;

      if (user?.activated) {
        helpers.successAlert(response.data.message);
        commit("setAuthUser", user);
        await dispatch("editorModule/setWorkSpacesAction", workspaces, { root: true });
        router.push({ name: "authEditorDashboard" });
      } else {
        commit("setNewlyResgistered", user);
        router.push({ name: "verifyEmailPage" });
      }
    } catch (error) {
      helpers.errorResponseHandler(error);
    } finally {
      commit("setLoader", { loader: "signInLoader", value: false });
    }
  },

  async registerNewAccountAction({ commit }, user) {
    try {
      commit("setLoader", { loader: "signUpLoader", value: true });
      const response = await api.post("/signup", user);
      commit("setNewlyResgistered", response.data.data);
      router.push({ name: "verifyEmailPage" });
    } catch (error) {
      helpers.errorResponseHandler(error);
    } finally {
      commit("setLoader", { loader: "signUpLoader", value: false });
    }
  },

  async logoutEditorAction({ commit }) {
    try {
      commit("setLoader", { loader: "signOutLoader", value: true });
      await api.get("/signout");
    } catch (error) {
      helpers.errorResponseHandler(error);
    } finally {
      commit("setLoader", { loader: "signOutLoader", value: false });
      commit("setAuthUser", {});
      localStorage.removeItem(constants.APP_STORE);
      router.push({ name: "editorSignIn" });
    }
  },

  async submitVerificationPinAction({ commit, state }, pin) {
    try {
      const data = {
        pin,
        user_id: state.newlyRegistered?.id,
      };
      commit("setLoader", { loader: "verifyEmailLoader", value: true });

      const response = await api.post("/verify/email", data);
      if (response.data?.data?.activated) {
        commit("setAuthUser", response.data.data);
        helpers.successAlert(response.data.message);
        router.push({ name: "authEditorDashboard" }).then(() => {
          commit("setShowEmailVerification", false);
        });
      } else {
        commit("setNewlyResgistered", response.data.data);
        commit("setShowEmailVerification", true);
        router.push({ name: "verifyEmailPage" });
      }
    } catch (error) {
      helpers.errorResponseHandler(error);
    } finally {
      commit("setLoader", { loader: "verifyEmailLoader", value: false });
    }
  },

  async resendVerificationPinAction({ commit, state }) {
    try {
      const data = {
        user_id: state.newlyRegistered?.id,
      };

      commit("setLoader", { loader: "resendPinLoader", value: true });
      const response = await api.post("/resend/pin", data);
      helpers.successAlert(response.data.message);
    } catch (error) {
      helpers.errorResponseHandler(error);
    } finally {
      commit("setLoader", { loader: "resendPinLoader", value: false });
    }
  },

  async submitForgotPasswordAction({ commit }, payload) {
    try {
      commit("setLoader", { value: true });
      const response = await api.post("/password/forgot", payload);
      router.push({ name: "editorSignIn" }).then(() => {
        helpers.successAlertBox("Link Sent!", response.data.message);
      });
    } catch (error) {
      helpers.errorResponseHandler(error);
    } finally {
      commit("setLoader", { value: false });
    }
  },

  async submitResetPasswordAction({ commit }, payload) {
    try {
      commit("setLoader", { value: true });
      const response = await api.post("/password/reset", payload);
      router.push({ name: "editorSignIn" }).then(() => {
        helpers.successAlertBox("", response.data.message);
      });
    } catch (error) {
      helpers.errorResponseHandler(error);
    } finally {
      commit("setLoader", { value: false });
    }
  },

  async forceUserToLoginPageAction({ commit }) {
    commit("setAuthUser", {});
    localStorage.removeItem(constants.APP_STORE);
    router.push({ name: "editorSignIn" });
  },

  showLoaderAction({ commit }, payload) {
    const { loader, value } = payload;
    commit("setLoader", { loader, value });
  },

  async getInvitationDetailsAction({ commit, state }, payload) {
    try {
      if (state.invitationToken != payload.token) {
        commit("RESET_WORKSPACE_INVITATION");
      }

      const params = {
        ...payload,
      };

      NProgress.start();
      const res = await api.get("/invitation/details", { params });
      commit("SET_WORKSPACE_INVITATION", res.data.data);
      commit("SET_INVITATION_TOKEN", payload.token);
    } catch (error) {
      helpers.errorResponseHandler(error);
    } finally {
      NProgress.done();
    }
  },

  async submitInvitationAcceptanceAction({ dispatch }, payload) {
    try {
      NProgress.start();
      const res = await api.post("/invitation/acceptance", payload);
      helpers.successAlert(res.data.message);
      dispatch("handleInvitationAcceptedSuccessAction", res.data.data);
    } catch (error) {
      helpers.errorResponseHandler(error);
    } finally {
      NProgress.done();
    }
  },

  async acceptInvitationWithGoogleAction({ dispatch }, payload) {
    try {
      NProgress.start();
      const res = await api.post("/invitation/acceptance/with/google", payload);
      helpers.successAlert(res.data.message);
      dispatch("handleInvitationAcceptedSuccessAction", res.data.data);
    } catch (error) {
      helpers.errorResponseHandler(error);
    } finally {
      NProgress.done();
    }
  },

  async handleInvitationAcceptedSuccessAction({ commit, dispatch }, response) {
    await commit("setAuthUser", response.user);
    await dispatch("editorModule/setWorkSpacesAction", response.workspaces, { root: true });
    await dispatch("editorModule/handleSetCurrentWorkspaceAction", response.workspace, {
      root: true,
    });
  },
};

const getters = {
  authUser: state => state.authUser,
  newlyRegistered: state => state.newlyRegistered,
  isLoggedIn: state => (state.authUser?.id && state.authUser?.activated ? true : false),
  token: state => state.authUser?.editor_api_token || "",
  buttonLoader: state => state.showLoader.buttonLoader,
  signInLoader: state => state.showLoader.signInLoader,
  googleLoginLoader: state => state.showLoader.googleLoginLoader,
  signUpLoader: state => state.showLoader.signUpLoader,
  verifyEmailLoader: state => state.showLoader.verifyEmailLoader,
  resendPinLoader: state => state.showLoader.resendPinLoader,
  signOutLoader: state => state.showLoader.signOutLoader,
  showEmailVerification: state => state.showEmailVerification,
  workspaceInvitation: state => state.workspaceInvitation,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
