import jQuery from "jquery";
import axios from "@/services/axios";
import swal from "sweetalert2";

window.jQuery = window.$ = jQuery;

window.Swal = swal;
const Toast = swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
window.Toast = Toast;

window.axios = axios;
window.api = axios;
