<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
    //
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700&display=swap");
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-Bold.ttf");
  font-weight: bold;
}

html,
body {
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
}
#app {
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
